export { default as FaqFGTS } from './faq/faq-fgts'
export { default as Banks } from './banks/banks'
export { default as Banner } from './banner/banner'
export { default as Benefits } from './benefits/benefits'
export { default as Faq } from './faq/faq'
export { default as Footer } from './footer/footer'
export { default as FooterLP } from './footer-lp/footer-lp'
export { default as HeaderMobile } from './header/header-mobile'
export { default as Header } from './header/header'
export { default as HeaderLP } from './header-lp/header-lp'
export { default as Modal } from './modal/modal'
export { default as NavItem } from './navbar/nav-item'
export { default as NavMobile } from './navbar/nav-mobile'
export { default as Navbar } from './navbar/navbar'
export { default as NavItemLP } from './navbar-lp/nav-item-lp'
export { default as NavMobileLP } from './navbar-lp/nav-mobile-lp'
export { default as NavbarLP } from './navbar-lp/navbar-lp'
export { default as Panel } from './panel/panel'
export { default as Reviews } from './reviews/reviews'
export { default as Spotlight } from './spotlight/spotlight'

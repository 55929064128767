import FOUNDER from './FOUNDER.webp'
import CONFRATERNIZACAO_TROFEU from './CONFRATERNIZACAO_TROFEU.webp'
import PESSOA_OLHANDO_PRA_VOCÊ from './PESSOA_OLHANDO_PRA_VOCÊ.webp'
import QUEM_SOMOS from './QUEM_SOMOS.webp'
import EQUIPE_TODOS_AMARELO from './EQUIPE_TODOS_AMARELO.webp'
import FOUNDER_CONFRATERNIZACAO from './FOUNDER_CONFRATERNIZACAO.webp'
import EQUIPE_TODOS_VERMELHO from './EQUIPE_TODOS_VERMELHO.webp'
import EQUIPE_UNIDA from './EQUIPE_TODOS_AMARELO.webp'
import LOGO_AMERICA_PARTNERS from './LOGO_AMERICA_PARCEIROS.webp'
import IDOSO_REALIZANDO_EMPRESTIMO from './IDOSO_REALIZANDO_EMPRESTIMO.webp'
import CASA_DOS_SONHOS from './CASA_DOS_SONHOS.webp'
import AUMENTAR_LUCRO from './AUMENTAR_LUCRO.webp'
import BANCO_BANRISUL from './BANCO_BANRISUL.webp'
import BANCO_BRADESCO from './BANCO_BRADESCO.webp'
import BANCO_CAIXA from './BANCO_CAIXA.webp'
import BANCO_SAFRA from './BANCO_SAFRA.webp'
import BANCO_PAN from './BANCO_PAN.webp'
import BANCO_SANTANDER from './BANCO_SANTANDER.webp'
import BANCO_C6 from './BANCO_C6.webp'
import BANCO_ITAU from './BANCO_ITAU.webp'
import EMPRESTIMO_CONSIGNADO_APOSENTADOS from './EMPRESTIMO_CONSIGNADO_APOSENTADOS.webp'
import CARTAO_CREDITO from './CARTAO_CREDITO.webp'
import CRESCIMENTO_EMPRESA from './CRESCIMENTO_EMPRESA.webp'
import PAGAR_DIVIDAS from './PAGAR_DIVIDAS.webp'
import EDUCACAO_ESTUDOS from './EDUCACAO_ESTUDOS.webp'
import EMPRESTIMO_ADULTOS_CONVERSANDO from './EMPRESTIMO_ADULTOS_CONVERSANDO.webp'
import IMPULSIONE_RECEITA from './IMPULSIONANDO_RECEITA.webp'
import INVESTIMENTO from './INVESTIMENTO.webp'
import MOVEIS_CASA from './MOVEIS_CASA.webp'
import PARCEIROS from './PARCEIROS.webp'
import SAUDE_INVESTIMENTO from './SAUDE_INVESTIMENTO.webp'
import SONHOS from './SONHOS.webp'
import VIAGEM from './VIAGEM.webp'
import BANCO_BRASIL from './BANCO_BRASIL.webp'
import CONTE_CONOSCO from './CONTE_CONOSCO.webp'
import DUAS_PESSOAS_CONVERSANDO from './DUAS_PESSOAS_CONVERSANDO.webp'
import LOGO_AMERICA_FINANCEIRA_SEGURO from './LOGO_AMERICA_FINANCEIRA_SEGURO.webp'
import LOGO_AMERICA_LETRAS_AZUL from './LOGO_AMERICA_LETRAS_AZUL.webp'
import EQUIPE_TODA from './EQUIPE_TODA.jpg'
import MULHER_CELULAR from './MULHER_CELULAR.jpg'
import DUAS_MENINAS from './DUAS_MENINAS.jpg'
import DUAS_PESSOAS_CONVERSAND_EMPRESTIMO from './DUAS_PESSOAS_CONVERSANDO_EMPRESTIMO.jpg'
import AREA_DE_ACESSO from './AREA_DE_ACESSO.jpg'
import ASSISTENCIA_SOCIAL from './ASSISTENCIA_SOCIAL.gif'
import BANK from './BANK.png'
import PESQUISA from './PESQUISA.png'
import SEGURADORAS from './SEGURADORAS.png'
import COMPUTADOR from './COMPUTADOR.gif'
import EMAIL from './EMAIL.gif'
import LOGO_TRABALHAR_1 from './LOGO_TRABALHAR_1.jpg'
import LOGO_TRABALHAR_2 from './LOGO_TRABALHAR_2.jpg'
import LOGO_TRABALHAR_3 from './LOGO_TRABALHAR_3.jpg'
import LOGO_TRABALHAR_4 from './LOGO_TRABALHAR_4.jpg'
import PAI_FILHO_MAE from './PAI_FILHO_MAE.png'
import PESSOA_EMPRESARIA from './PESSOA_EMPRESARIA.png'

const IMAGE = {
  PESSOA_EMPRESARIA,
  PAI_FILHO_MAE,
  LOGO_TRABALHAR_3,
  LOGO_TRABALHAR_4,
  LOGO_TRABALHAR_1,
  LOGO_TRABALHAR_2,
  COMPUTADOR,
  EMAIL,
  BANK,
  PESQUISA,
  SEGURADORAS,
  ASSISTENCIA_SOCIAL,
  EQUIPE_TODA,
  MULHER_CELULAR,
  DUAS_MENINAS,
  DUAS_PESSOAS_CONVERSAND_EMPRESTIMO,
  AREA_DE_ACESSO,
  SAUDE_INVESTIMENTO,
  MOVEIS_CASA,
  INVESTIMENTO,
  EDUCACAO_ESTUDOS,
  SONHOS,
  LOGO_AMERICA_LETRAS_AZUL,
  FOUNDER,
  CONFRATERNIZACAO_TROFEU,
  PESSOA_OLHANDO_PRA_VOCÊ,
  QUEM_SOMOS,
  EQUIPE_TODOS_AMARELO,
  FOUNDER_CONFRATERNIZACAO,
  EQUIPE_TODOS_VERMELHO,
  EQUIPE_UNIDA,
  LOGO_AMERICA_PARTNERS,
  IDOSO_REALIZANDO_EMPRESTIMO,
  CASA_DOS_SONHOS,
  AUMENTAR_LUCRO,
  BANCO_BANRISUL,
  BANCO_BRADESCO,
  BANCO_CAIXA,
  BANCO_SAFRA,
  BANCO_PAN,
  BANCO_SANTANDER,
  LOGO_AMERICA_FINANCEIRA_SEGURO,
  BANCO_C6,
  BANCO_ITAU,
  EMPRESTIMO_CONSIGNADO_APOSENTADOS,
  CARTAO_CREDITO,
  CRESCIMENTO_EMPRESA,
  PAGAR_DIVIDAS,
  EMPRESTIMO_ADULTOS_CONVERSANDO,
  IMPULSIONE_RECEITA,
  PARCEIROS,
  VIAGEM,
  BANCO_BRASIL,
  CONTE_CONOSCO,
  DUAS_PESSOAS_CONVERSANDO,
}

export default IMAGE
